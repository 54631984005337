export const environment = {
  production: false,
  server_url: 'https://api.pattayanight.club/',
  translate_server_url: 'https://app.translate.pattayanight.club/',
  apiKey: 'AIzaSyCeLKb75n9DKSn7JyIqjUj1kwNsamF0saM',
  IMAGE_URL: 'https://api.pattayanight.club/',
  firebase: {
    apiKey: "AIzaSyDz9ZMUZxHG0HMcWalNKyGWvDZmbPyOQBU",
    authDomain: "pattayanightlifeguide-364b0.firebaseapp.com",
    databaseURL: "https://pattayanightlifeguide-364b0.firebaseio.com",
    projectId: "pattayanightlifeguide-364b0",
    storageBucket: "pattayanightlifeguide-364b0.appspot.com",
    messagingSenderId: "978244665919",
    appId: "1:978244665919:web:dbfa25dd47d6a2db3dae8a",
    measurementId: "G-M0TTLEPSG5"
  }
};
